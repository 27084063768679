import { Component, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

import { ToastService } from './toast.service';

@Component({
  selector: 'app-toasts',
  template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [header]="toast.headertext"
      [class]="toast.classname"
      [autohide]="toast.autohide || true"
      [delay]="toast.delay || 4000"
      (hide)="toastService.remove(toast)"
      (click)="toastService.remove(toast);routes(toast.destination)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  `,
  host: { '[class.ngb-toasts]': 'true', '[class.fade]': 'animation' },
  styleUrls: ['./toast.container.scss'],
})
export class ToastsContainer {
  constructor(public toastService: ToastService, public route: Router) { }
  show = false;
  autohide = true;
  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
  routes(destination) {
    if (destination) {
      this.route.navigate([destination]);
    }

  }
}
