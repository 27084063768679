import { Injectable, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];
  constructor(private router: Router) {}
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
  TextOnlyShort(message) {
    this.show(message, {
      classname: 'bg-warning text-dark',
    });
  }
  TextSuccess(messages) {
    this.show(messages, {
      classname: 'bg-success text-light',
    });
  }
  TextOnlyLong(message) {
    this.show(message, {
      classname: 'bg-warning text-dark',
      delay: 10000,
    });
  }
  chatNotif(message) {
    // console.log(message);
    this.show(message, {
      classname: 'bg-primary text-light left-toast',
      delay: 4000,
      destination: '/chat',
    });
  }
  TextOnlyLongLeft(message) {
    this.show(message, {
      classname: 'bg-warning text-dark left-toast',
      delay: 10000,
    });
  }
  ErrHubungiAdmin() {
    this.show('Hubungi CS +62 813-4768-3038 untuk info lebih lanjut', {
      classname: 'bg-warning text-dark',
      delay: 10000,
    });
  }
  ErrHandlerWithCode(message, code?) {
    // console.log('ini code' + code);
    if (code != null) {
      if (code == 403) {
        this.show(
          '(' +
            code +
            ') : ' +
            'Role must be admin, you are not. So you need to Relogin (with admin account) and refresh',
          {
            classname: 'bg-danger text-light',
          }
        );
        this.router.navigate(['user/login']);
        location.reload();
      } else if (code == 401) {
        this.show('(' + code + ') : ' + 'Your token not valid', {
          classname: 'bg-danger text-light',
        });

        this.router.navigate(['user/login']);
        location.reload();
      }
    } else {
      this.show(message, { classname: 'bg-danger text-light' });
    }
  }
}
