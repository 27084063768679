import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { ToastService } from "../../shared/toast/toast.service";
import { Injectable, Injector } from "@angular/core";
import { LoadingService } from "../components/loading/loading.service";
import { Router } from "@angular/router";
import { AuthService } from "../../shared/auth/auth.service";
import { MatDialog } from "@angular/material/dialog";

export const InterceptorSkipHeader = "X-Skip-Interceptor";
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private loadingService: LoadingService,
    private router: Router,
    private injector: Injector,
    private matDialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authService = this.injector.get(AuthService);

    // this.loadingService.openDialog();
    return next
      .handle(
        request.clone({
          headers: request.headers.set(
            "Authorization",
            "Bearer " + authService.getToken()
          ),
        })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log("err occured", error);
          // if (error.error.code == 401) {
          //   window.location.href = '/user/login';
          // }
          if (error.status === 401 || error.status === 403) {
            this.matDialog.closeAll();
            localStorage.clear();
            this.router.navigateByUrl(`/user/login`);
          }
          // if (error.status === 401 || error.status === 403) {
          //   localStorage.clear();
          //   this.router.navigateByUrl(`/user/login`);
          // }
          // this.toastService.ErrHandler(
          //   error.message ?? JSON.stringify(error),
          //   error.status
          // );
          // if (error.error.code == 500) {
          //   this.bottomSheetService.openErrorSheet(error.error.message);
          // }
          // if (error.error.code == 404) {
          //   this.bottomSheetService.openErrorSheet(error.error.message);
          // }
          // if (error.status == 0) {
          //   this.bottomSheetService.openErrorSheet(error.message);
          // }
          return throwError(error);
        }),
        finalize(() => {
          // this.loadingService.hideDialog();
        })
      );
    // as Observable<HttpEvent<any>>;
  }
}
