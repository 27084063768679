import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { async } from 'rxjs/internal/scheduler/async';
import { ToastService } from '../toast/toast.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    private router: Router,
    public toastService: ToastService
  ) {}

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): boolean | Observable<boolean> | Promise<boolean> {
  // const isAuth = this.authService.getIsAuth();

  // if (!isAuth) {
  //   this.router.navigate(['/user/login']);
  // }
  // return isAuth;
  // }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    if (this.authService.isLoggedIn()) {
      if (
        !route.data.roles ||
        (route.data.roles &&
          route.data.roles.includes(this.authService.getUser().role))
      ) {
        const x = JSON.parse(localStorage.getItem('userSubscription'));

        const expiredDate = x?.filter((obj) =>
          obj.hasOwnProperty('expiredDate')
        ).length;
        if (expiredDate > 0) {
          if (
            x.some((x) => {
              return x.isActivated == true;
            })
          ) {
            let notExpired = true;
            // x.forEach((element) => {
            //   if (element.isActivated == true) {
            //     if (<any>new Date(element.expiredDate) - <any>new Date() > 0) {
            //       notExpired = true;
            //     } else {
            //       this.router.navigate(['user/login']).then(() => {
            //         this.toastService.TextOnlyLong(
            //           'Harap lakukan pembayaran agar dapat menggunakan dashboard'
            //         );
            //       });
            //       notExpired = false;
            //     }
            //   }
            // });
            return notExpired;
          } else {
            // this.router.navigate(['user/login']).then(() => {
            //   this.toastService.TextOnlyLong(
            //     'Harap lakukan pembayaran agar dapat menggunakan dashboard'
            //   );
            // });
            return true;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      this.router.navigate(['user/login']).then(() => {
        this.toastService.TextOnlyLong(
          'Harap lakukan pembayaran agar dapat menggunakan dashboard'
        );
      });
    }
  }

  checkLogin(url: string) {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['user/login']);
      this.toastService.TextOnlyLong(
        'Harap lakukan pembayaran agar dapat menggunakan dashboard'
      );
    }
  }
}
