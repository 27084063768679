import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { InterceptorSkipHeader } from '../error/http-error.interceptor';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class AssetsService {
  rewards: any;
  ip = environment.ip;
  constructor(private http: HttpClient) {}
  dataToken = JSON.parse(localStorage.getItem('userData'));
  // headersWithout = new HttpHeaders().set(InterceptorSkipHeader, '');
  headers = new HttpHeaders();
  getCityLists() {
    return this.http.get(
      this.ip + '/resources/cities'
      // {
      //   headers: this.headersWithout,
      // }
    );
  }

  getEducationLevelList() {}

  getProfessionLists() {
    return this.http.get(
      this.ip + '/resources/kuteam/professions'
      // {
      //   headers: this.headersWithout,
      // }
    );
  }

  getDivisionLists() {
    return this.http.get(
      this.ip + '/resources/divisions'
      // {
      //   headers: this.headersWithout,
      // }
    );
  }

  getJobLists(pageIndex: any, pageSize: any) {
    return this.http.get(this.ip + '/jobs/postedJobs', {
      headers: this.headers.set(
        'Authorization',
        'Bearer ' + this.dataToken.accessToken
      ),
      params: new HttpParams().set('pageKey', pageIndex).set('limit', pageSize),
    });
  }

  fetchBank() {
    let url = `${this.ip}/resources/irisBank`;

    let headers = new HttpHeaders();

    return this.http.get(url, { headers: headers });
  }

  logActivity(type: any, detail: any, extra: any) {
    const body = {
      type: type,
    };
    if (detail) {
      body['detail'] = detail;
    }
    if (extra) {
      body['extra'] = extra;
    }

    let isActive = false;
    return isActive
      ? this.http.post(this.ip + '/logs/activity', body, {
          headers: this.headers.set(
            'Authorization',
            'Bearer ' + this.dataToken?.accessToken
          ),
        })
      : Observable.create((observer: any) => {
          observer.next({
            type: 'userActivity',
            isActive: false,
          });
        });
  }
}
