import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';
import { ComfirmComponent } from './comfirm.component';

@Injectable()
export class ComfirmService {
  private dialogRef: MatDialogRef<ComfirmComponent>;

  constructor(private dialog: MatDialog) {}

  openDialog(): void {
    this.dialogRef = this.dialog.open(ComfirmComponent, {
      data: undefined,
      // maxHeight: '100%',
      width: '280px',
      // maxWidth: '100%',
      disableClose: true,
      hasBackdrop: true,
    });
  }

  noComfirm() {
    this.dialogRef.close(false);
  }

  yesComfirm() {
    this.dialogRef.close(true);
  }

  yesAnswer() {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      })
    );
  }
}
