import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { getUserRole } from "../../utils/util.js";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult, SilentRequest } from "@azure/msal-browser";
// import { InterceptorSkipHeader } from '../error/http-error.interceptor';

@Injectable()
export class AuthService {
  dataUser: any;
  userData: any;
  ipManagement = environment.ip;
  redirectUrl: string;
  isAuthenticated: boolean = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    private msalService: MsalService
  ) {}
  // withoutInterceptor = new HttpHeaders().set(InterceptorSkipHeader, '');
  loginRedirect(state: any): void {
    console.log(state);
    this.msalService.loginRedirect({
      scopes: [
        "openid",
        "profile",
        "https://authkukerja.onmicrosoft.com/534ccce7-6931-4af3-9a31-d066d9009675/access_as_user",
      ],
      // redirectUri: "http://localhost:4200",
      // redirectUri: "https://kukerja-angular-v2.web.app",
      // redirectUri: "https://kukerja-panel-demo.web.app",
      redirectUri: "https://panel.kukerja.id",
      state: JSON.stringify(state),
    });
  }

  handleRedirectPromise(): void {
    console.log("working ??");
    this.msalService.instance
      .handleRedirectPromise()
      .then((response: AuthenticationResult | null) => {
        console.log("its working", response);
        if (response) {
          this.msalService.instance.setActiveAccount(response.account);
          if (response.state) {
            const state = JSON.parse(response.state);
            console.log([state.redirectTo], { state: state });
            this.router.navigate([state.redirectTo], {
              state: state,
              queryParams: {
                state: "exists",
              },
            });
          }
          this.acquireToken();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  acquireToken() {
    const account = this.msalService.instance.getActiveAccount();
    if (account) {
      const silentRequest: SilentRequest = {
        account,
        scopes: [
          "openid",
          "profile",
          "https://authkukerja.onmicrosoft.com/534ccce7-6931-4af3-9a31-d066d9009675/access_as_user",
        ],
      };
      return this.msalService.instance.acquireTokenSilent(silentRequest);
    }
  }

  checkNumber(number: any) {
    const data = {
      phonenumber: number,
      type: "Employer",
    };
    return this.http.post(
      this.ipManagement + "/auth/checknumber",
      data
      // {
      //   headers: this.withoutInterceptor,
      // }
    );
  }

  sendLoginRequest(number: any, idToken: any, type: any) {
    const data = {
      idToken: idToken,
      phonenumber: number,
      type: type,
    };
    return this.http.post(this.ipManagement + "/auth/login", data);
  }

  sendAzureLoginRequest({ accessToken }) {
    let url = `${this.ipManagement}/auth/phonenumber-login`;
    // console.log("Authorization", `Bearer ${accessToken}`);
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + accessToken)
      .set("Content-Type", "application/json");

    return this.http.get(url, {
      headers: headers,
    });
  }

  getLoggedUserProfile() {
    const dataToken = JSON.parse(localStorage.getItem("userData"));
    const headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + dataToken?.accessToken
    );

    return this.http.get(this.ipManagement + "/users/profile", {
      headers: headers,
    });
  }
  //   "token": {
  //     "tokenType": "Bearer",
  //     "accessToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MDM1MzQ1NTcsImlhdCI6MTYwMjkyOTc1Nywic3ViIjoiNWY3MGUyMjUwZGRiYTY2NjY4ZTJjYjg2In0.eVBuJa6sw1XPTAvVsDbI5ifH8tof0ZwIyPAIXsdZlHE",
  //     "refreshToken": "5f70e2250ddba66668e2cb86.cd48c1180be83e9db13ca00ae37424fa22999490dd13b2acde2ba3d453a6751f1dc93e7b5be2967e",
  //     "expiresIn": "2020-10-24T10:15:57.611Z"
  // },
  logout() {
    this.msalService.logoutRedirect();
    localStorage.removeItem("userData");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("chatRoom");
    localStorage.removeItem("userSubscription");
    localStorage.clear();
  }

  isLoggedIn() {
    if (localStorage.getItem("userData")) {
      return true;
    }
    return false;
  }

  getUser() {
    return {
      role: getUserRole(),
    };
  }

  loginWithCode(code: any) {
    const body = {
      code: code,
    };
    return this.http.post(this.ipManagement + "/auth/code", body);
  }
  sendLoginGoogleRequest({ idToken, type }) {
    const data = {
      // access_token: idToken,
      idToken,
      type: type,
    };
    console.log(data);
    // let url = `${this.ipManagement}/auth/google`;
    let url = `${this.ipManagement}/auth/web-login`;
    return this.http.post(url, data);
  }
  autoAuthUser() {
    const authInformation = this.getAuthData();
    // console.log('authInformation', authInformation);
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn =
      new Date(authInformation.userData.expiresIn).getTime() - now.getTime();

    if (expiresIn > 0) {
      this.isAuthenticated = true;
    } else {
      localStorage.clear();
      window.location.reload();
      const dataresponse = {
        refreshToken: authInformation.userData.refreshToken,
        email: authInformation.userData.email,
      };

      return dataresponse;
    }
  }

  private getProfile() {
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    if (!userProfile) {
      return;
    }
    return {
      userProfile: userProfile,
    };
  }

  private getAuthData() {
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      return;
    }
    return {
      userData: userData,
    };
  }
  // dataToken: dataToken,
  getIsAuth() {
    return this.isAuthenticated;
  }

  getToken() {
    return JSON.parse(localStorage.getItem("userData"))
      ? JSON.parse(localStorage.getItem("userData"))["accessToken"]
      : "";
  }
}
