<div style="
display: flex;
flex-direction: column;
align-items: center;
" class="col-md-12">
  <div class="row justify-content-center">
    <div mat-dialog-actions>
      <div style="font-size: 18px; font-weight: bold;">Kamu Yakin Dengan Pilihan Ini?</div>
    </div>
  </div>
  <div style="
  width: 100px;
  content: url(../../../../assets/images/warning.png);
  margin: 15px 0;
  ">
  </div>
  <div class="row justify-content-center">
    <div mat-dialog-content>
      <button style="width: 100px;" class="cancel-button mr-4" (click)="comfirmService.noComfirm()">
        Batal
      </button>
      <button style="width: 100px;" class="ok-button" (click)="comfirmService.yesComfirm()">
        OK
      </button>
    </div>
  </div>
</div>