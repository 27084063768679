import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { LoadingBarModule, LOADING_BAR_CONFIG } from "@ngx-loading-bar/core";
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from "@ngrx/router-store";
import { CustomSerializer } from "./utils/utils";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { ToastService } from "./shared/toast/toast.service";
import { chatReducer } from "./core/containers/chat-comp/state/chat.reducers";
import { ChatCompModule } from "./core/containers/chat-comp/chat-comp.module";
import { ChatEffects } from "./core/containers/chat-comp/state/chat.effects";
import { MaterialModule } from "./core/components/material/material.module";
import { RouterModule } from "@angular/router";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from "@azure/msal-angular";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    StoreModule.forRoot({}),
    StoreRouterConnectingModule.forRoot({ stateKey: "router" }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: "Kukerja Panel",
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: "534ccce7-6931-4af3-9a31-d066d9009675",
          authority:
            "https://authkukerja.b2clogin.com/authkukerja.onmicrosoft.com/B2C_1A_PH_SUSI",
          knownAuthorities: ["authkukerja.b2clogin.com"],
          // redirectUri: "http://localhost:4200",
          // postLogoutRedirectUri: "http://localhost:4200",
          // redirectUri: "https://kukerja-panel-demo.web.app",
          // postLogoutRedirectUri: "https://kukerja-panel-demo.web.app",
          // redirectUri: "https://kukerja-angular-v2.web.app",
          // postLogoutRedirectUri: "https://kukerja-angular-v2.web.app",
          redirectUri: "https://panel.kukerja.id",
          postLogoutRedirectUri: "https://panel.kukerja.id",
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect, // Set interaction type to Redirect
        authRequest: {
          scopes: [
            "openid",
            "profile",
            "https://authkukerja.onmicrosoft.com/534ccce7-6931-4af3-9a31-d066d9009675/access_as_user",
          ],
        },
      },
      {
        interactionType: InteractionType.Redirect, // Set interaction type to Redirect
        protectedResourceMap: new Map([
          ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
        ]),
      }
    ),
  ],
  providers: [
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    // ToastService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },

    MsalService,
    MsalGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
