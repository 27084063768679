import { Component, OnInit } from '@angular/core';
import { ComfirmService } from './comfirm.service';

@Component({
  selector: 'app-comfirm',
  templateUrl: './comfirm.component.html',
  styleUrls: ['./comfirm.component.scss'],
})
export class ComfirmComponent implements OnInit {
  constructor(public comfirmService: ComfirmService) {}

  ngOnInit(): void {}
}
