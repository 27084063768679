<div class="col-md-12">
  <div class="row justify-content-center">
    <div mat-dialog-actions>
      <div class="loading-spinner">
        <mat-spinner diameter="80" strokeWidth="5"></mat-spinner>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <h1 mat-dialog-title>Loading data</h1>
  </div>
  <div class="row justify-content-center">
    <div mat-dialog-content>Please wait</div>
  </div>
</div>