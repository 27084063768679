import { Component, HostListener, OnInit } from "@angular/core";
import { AuthService } from "./shared/auth/auth.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { InternetNotFoundModalComponent } from "./core/containers/modals/internet-not-found-modal/internet-not-found-modal.component";
import { MsalService } from "@azure/msal-angular";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "Kukerja - Panel";
  isOnline: boolean = navigator.onLine;
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private msalService: MsalService
  ) {}
  async ngOnInit() {
    console.log(localStorage);
    this.checkInternetConnection();
    this.authService.autoAuthUser();
    await this.msalService.instance.initialize();
    const currentAccount = this.msalService.instance.getActiveAccount();
    this.authService.handleRedirectPromise();
    // const x = localStorage.getItem('userData');
    // // console.log(x);
    // if (x) {
    //   // console.log('wew');
    //   this.authService.getLoggedUserProfile().subscribe((resp) => {
    //     localStorage.setItem(
    //       'userSubscription',
    //       JSON.stringify(resp['subscribe'])
    //     );
    //     localStorage.setItem(
    //       'userProfile',
    //       JSON.stringify(resp['profile_employer'])
    //     );
    //   });
    // }

    // else {
    //   this.router.navigate(['/user/login'])
    // }
  }

  checkInternetConnection() {
    if (!this.isOnline) {
      this.dialog.open(InternetNotFoundModalComponent, { disableClose: true });
    }
  }
}
