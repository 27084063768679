<div
    style="width: 250px; padding: 5px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
    <div style="content: url('../../../../../assets/images/astronot/no_internet.png'); width: 250px;">
    </div>
    <p style="margin-top: 5px; text-align: center;" class="bold">
        Anda sedang offline, mohon periksa kembali koneksi internet Anda
    </p>
    <button (click)="close()" mat-raised-button class="raisedButton1"
        style="border-style: none; min-width: 250px; height: 45px; border-radius: 4px;">Refresh
        &
        Tutup</button>
</div>