import { environment } from "src/environments/environment";

export const getUserRole = () => {
  let role = environment.defaultRole;
  try {
    let x = JSON.parse(localStorage.getItem("userData"));
    role = x.type || environment.defaultRole;
  } catch (error) {
    // some err
    // console.log(">>>> src/app/utils/util.js : getUserRole -> error", error);
    role = environment.defaultRole;
  }
  return role;
};

export const getThemeColor = () => {
  let color = environment.defaultColor;
  try {
    color =
      localStorage.getItem(environment.themeColorStorageKey) ||
      environment.defaultColor;
  } catch (error) {
    // console.log(">>>> src/app/utils/util.js : getThemeColor -> error", error);
    // color = environment.defaultColor;
  }
  return color;
};
export const setThemeColor = (color) => {
  try {
    if (color) {
      localStorage.setItem(environment.themeColorStorageKey, color);
    } else {
      localStorage.removeItem(environment.themeColorStorageKey);
    }
  } catch (error) {
    // console.log(">>>> src/app/utils/util.js : setThemeColor -> error", error);
  }
};
