import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AuthService } from "./auth/auth.service";
import { AuthGuard } from "./auth/auth.guard";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { DateAgoPipe } from "./pipes/date-ago.pipe";
import { AssetsService } from "./services/assets.service";
import { WindowService } from "./auth/window.service";
import { ToastService } from "./toast/toast.service";
import { ToastsContainer } from "./toast/toast.container";
import { NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingComponent } from "./components/loading/loading.component";
import { LoadingService } from "./components/loading/loading.service";
import { ErrorHandlerModule } from "./error/error-handler.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ComfirmComponent } from "./components/comfirm/comfirm.component";
import { ComfirmService } from "./components/comfirm/comfirm.service";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [
    DateAgoPipe,
    ToastsContainer,
    LoadingComponent,
    ComfirmComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    RouterModule,
    NgbToastModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    MatProgressSpinnerModule,
    ErrorHandlerModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    RouterModule,
    DateAgoPipe,
    ToastsContainer,
    NgbToastModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    AssetsService,
    WindowService,
    ToastService,
    LoadingService,
    ComfirmService,
  ],
})
export class SharedModule {}
