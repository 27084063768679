import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-internet-not-found-modal',
  templateUrl: './internet-not-found-modal.component.html',
  styleUrls: ['./internet-not-found-modal.component.scss'],
})
export class InternetNotFoundModalComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  close() {
    window.location.reload();
    this.dialog.closeAll();
  }
}
