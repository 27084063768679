// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { UserRole } from "src/app/shared/models/roles";

// const env = "http://192.168.100.10:8081";
// const env = "https://panelbackenddemo.kukerja.id";
// const env = "https://www.panelbackenddemo.kukerja.id";
// const env = "http://localhost:8081";
const env = "https://admin42.kukerja.id";

export const environment = {
  production: false,
  ip: `${env}/management`,
  // ipAdmin: `${env}/admin`,
  ipv1: `${env}/api/v1`,
  ipSocket: `${env}`,
  // ip: 'https://admin42.kukerja.id/management',
  // ip: 'http://188.166.244.237:8081/management',
  // ip: 'http://188.166.244.237:8081/api/admin',
  // ip: 'https://api.kukerja.id/api/v1',
  adminRoot: "/app",
  defaultMenuType: "menu-default",
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  defaultDirection: "ltr",
  isAuthGuardActive: true,
  defaultRole: UserRole.Employer,
  defaultColor: "default",
  themeColorStorageKey: "kukerja-themecolor",
  isDarkSwitchActive: true,
  isMultiColorActive: true,
  firebase: {
    apiKey: "AIzaSyAvXVfw2lwZ9X_Ls7sCsGo2Bq6gflXq2QM",
    authDomain: "kukerja-indonesia.firebaseapp.com",
    databaseURL: "https://kukerja-indonesia.firebaseio.com",
    projectId: "kukerja-indonesia",
    storageBucket: "kukerja-indonesia.appspot.com",
    messagingSenderId: "339885625717",
    appId: "1:339885625717:android:7781b0876b0c67d625833c",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
